.Industries {
  padding: 0 12vw;
  margin-bottom: 100px;
}
.Industries h1 {
  font-size: 2.7rem;
  margin: 75px 0 75px 0;
}
.Industries .container-info {
  z-index: 5;
}
.Industries img {
  z-index: 1;
}
.Industries .card-img {
  position: absolute;
  left: 0px;
  top: -1px;
  z-index: -1;
  width: 279px;
  height: 464px;
  border-radius: 5px;
}
.Industries .bg-img {
  padding: 10px 0 0 12px;
  position: relative;
  right: 13px;
}
.Industries .industry {
  width: 279px;
  height: 464px;
  border: 1px solid black;
  border-radius: 5px;
  text-align: left;
  color: rgb(231, 231, 231);
  margin: 15px auto 0 auto;
  outline: 2px solid black;
  outline-offset: -2px;
  transition: outline-offset 200ms ease;
}
.Industries .industry:hover,
.Industries .industry:focus {
  outline: 3px solid rgb(0, 168, 45);
  background-color: rgba(0, 168, 45, 0.05);
  outline-offset: 2px;
}
.Industries .arrow {
  position: relative;
  top: 210px;
  width: 50px;
}
@media only screen and (max-width: 1468px) {
  .Industries #Crypto {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .Industries #Crypto {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .Industries h1 {
    font-size: 1.5rem;
    margin: 35px 0 25px 0;
    font-size: 1.7rem;
  }
  .Industries .bg-img {
    height: 226px;
  }
  .Industries .industry {
    left: 15vw;
    height: 230px;
  }
  .Industries .card-img {
    height: 230px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .Industries .arrow {
    position: relative;
    top: 1px;
  }
}
@media only screen and (max-width: 571px) {
  .Industries {
    margin-bottom: 50px;
  }
  .Industries .industry {
    left: 8vw;
  }
  .Industries .header-container {
    max-width: 330px;
    min-width: 330px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 900px) {
  .Industries {
    padding: 0;
  }
}/*# sourceMappingURL=Industries.css.map */