.form{
    min-height: 125vh;
    padding: 75px 10px 0 10px;
    h1{
        font-size: 1.97rem;    }
    .container{
    margin-top: 100px;
    padding: 25px 15px;
    max-width: 800px ;
    border-radius: 5px;
    min-height: 525px;        
    background-color: whitesmoke;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 

    }
    p{
        font-size: 0.85rem
    }
    textarea{
        padding: 15px 15px 0 10px;
        height: 205px;
        width: 310px;
        border-radius: 5px;
        margin-top: 15px;
        border-color: lightgray;
    }

    textarea:focus{
        border-color: rgb(0,168,45);
        outline: none;

    }

    textarea::placeholder{
        color: black;
    }

    input{
        max-width: 310px;
    }

    input:focus{
        border-color: rgb(0,168,45);
    }
    button{
        font-weight: bold;
        font-size: 1.5rem;
        height: 60px;
        width: 310px;
    }
    @media only screen and (max-width: 693px) {
        padding: 125px 10px 0 10px;
         .container{
            // height: 875px;
            margin-top: 15px;
            margin-bottom: 100px;
        }
        h1{
            font-size: 3rem;
        }

        .info{
            max-width: 350px;
            margin: 0 auto 0 auto;

        }
        textarea{
            height: 150px;
        }
    }
}