.BookAMeeting p {
  rgb(231, 231, 231)-space: nowrap;
}

@media only screen and (max-width: 900px) {
  .BookAMeeting p {
    rgb(231, 231, 231)-space: inherit;
  }
}

/*# sourceMappingURL=BookAMeeting.css.map */