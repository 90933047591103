.Industries{
    padding: 0 12vw;
    margin-bottom: 100px;
    
    h1{
        font-size: 2.7rem;
        margin: 75px 0 75px 0   ;
    }
    .container-info{
        z-index: 5;
    }

    img{
        z-index: 1;
    }

    .card-img{
        position: absolute;
        left: 0px;
        top: -1px;
        z-index: -1;
        width: 279px;
        height: 464px;
        border-radius: 5px;
    }

    .bg-img{
        padding: 10px 0 0 12px;
        position: relative;
        right: 13px;
    }
    .industry{
        width: 279px;
        height: 464px;
        border: 1px solid black;
        border-radius: 5px;
        text-align: left;
        color: rgb(231, 231, 231);
        margin: 15px auto 0 auto;
        outline: 2px solid black;
        outline-offset: -2px;
        transition: outline-offset 200ms ease;
    }
      
      .industry:hover,
      .industry:focus {
        outline: 3px solid rgb(0, 168, 45);
        background-color: rgba(0, 168, 45,0.05);

        outline-offset: 2px;
      }

    
    .arrow{
        position: relative;
        top: 210px;
        width: 50px;
    }

    // @media only screen and (max-width: 1109px) {
    //     .industry{
    //         left: 10vw;
    //     }
    // }

    // @media only screen and (max-width: 934px) {
    //     .industry{
    //         left: 3vw;
    //     }
    // }
    @media only screen and (max-width: 1468px) {
    #Crypto{
        display: none;
    }}

    @media only screen and (max-width: 1100px) {
        #Crypto{
            display: block;
        }}
    @media only screen and (max-width: 768px) {
        h1{
            font-size: 1.5rem;
            margin: 35px 0 25px 0   ;
            font-size: 1.7rem;
        }

        .bg-img{
            height: 226px;
        }
        .industry{
            left: 15vw;
            height: 230px;
        }

        .card-img{ 
            height: 230px; 
            object-fit: cover;
        }
        
        .arrow{
            position: relative;
            top: 1px;
        }
          
    }

    @media only screen and (max-width: 571px) {
        margin-bottom: 50px;
        .industry{
            left: 8vw;
        }

        .header-container{
            max-width: 330px;
            min-width: 330px;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 900px) {
        padding: 0;

        
    }

}