.Testimonials{
    padding-top: 150px;
    .container{
        max-width: 350px;
        margin-top: 50px;
        border: solid 1px lightgray ;
        padding: 25px;
        border-radius: 5px;
        background-color: whitesmoke;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
    }
    .delete-btn{
        margin: 10px auto 0 auto;
    }

    span{
        input{
            display: inline;
        }
        p{
            display: inline;
        }
        button{
            margin-left: 120px;
            display: inline;
        }
    }
}