.Business {
  padding-top: 15vh;
  min-height: 150vh;
}
.Business .info-btn {
  display: inline;
  padding: 5px;
  font-size: 2.5rem;
  border: none;
  position: absolute;
  top: 0px;
  right: 15px;
}
.Business .container {
  position: relative;
  margin-top: 50px;
  border: solid 1px lightgray;
  max-width: 750px;
  padding: 25px;
  border-radius: 5px;
  background-color: whitesmoke;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.Business .bold-header {
  font-weight: bold;
  text-align: left;
}
.Business label {
  font-size: 1.1rem;
  font-weight: bold;
}
.Business .form-btn {
  margin-top: 10px;
  width: 150px;
}
.Business .form-group {
  margin: 0 10px;
  display: inline-table;
}
.Business .form-text {
  color: black;
  position: relative;
  bottom: 10px;
}
.Business a {
  text-decoration: none;
}
.Business textarea {
  height: 105px;
}
.Business .info {
  position: relative;
  left: 25px;
  text-align: left;
  max-width: 650px;
}/*# sourceMappingURL=Business.css.map */