@charset "UTF-8";
.Reviews .prev-btn {
  position: relative;
  right: 25px;
  height: 75px;
}
.Reviews .review-img-btn {
  padding-bottom: 5px;
  margin-top: 25px;
  display: inline;
  width: 100px;
  cursor: pointer;
  height: 45px;
  transition: all 0.5s;
  transition: 0.5s;
  font-size: 2.5rem;
  margin-bottom: 15px;
  text-align: center;
}
.Reviews p {
  font-size: 1rem;
  position: relative;
  top: 2px;
}
.Reviews .next-btn:after {
  content: "»";
  position: absolute;
  opacity: 0;
  bottom: -9px;
  right: -10px;
  transition: 0.5s;
}
.Reviews .next-btn:hover {
  padding-right: 24px;
  padding-left: 8px;
}
.Reviews .next-btn:hover:after {
  opacity: 1;
  right: 10px;
}
.Reviews .prev-btn:after {
  content: "«";
  position: absolute;
  opacity: 0;
  bottom: -9px;
  left: -10px;
  transition: 0.5s;
}
.Reviews .prev-btn:hover {
  padding-left: 24px;
  padding-right: 8px;
}
.Reviews .prev-btn:hover:after {
  opacity: 1;
  left: 10px;
}
.Reviews .next-btn {
  position: relative;
  left: 25px;
}/*# sourceMappingURL=Reviews.css.map */