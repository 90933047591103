.FormTemplate{
        min-height: 125vh;
        padding: 100px 10px 0 10px;
        h1{
            font-size: 3rem; font-weight: bold;    
        }

        h5{
            text-align: left;
        }

        a{
            color: black;
            text-decoration: underline;
        }
        
        .container{
            position: relative;
            margin-top: 100px;
            padding: 25px 15px;
            max-width: 800px ;
            border-radius: 5px;
            height: 450px;        
            background-color: whitesmoke;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
        }
     
        .col1-info{
            padding-left: 45px;
        }
        p{
            font-size: 0.85rem
        }
        textarea{
            padding: 15px 15px 0 10px;
            height: 150px;
            width: 310px;
            border-radius: 5px;
            border-color: lightgray;
        }
        
        textarea:focus{
            border-color: rgb(0,168,45);
            outline: none;
    
        }
    
        textarea::placeholder{
            color: black;
        }
    
        input{
            max-width: 310px;
        }
    
        input:focus{
            border-color: rgb(0,168,45);
        }
        button{
            font-weight: bold;
            font-size: 1.5rem;
            height: 60px;
            width: 310px;
            margin-top: 10px;
        }
        @media only screen and (max-width: 732px) {
            .container{
                max-width: 420px;
                height: 850px;
                margin-top: 15px;
                margin-bottom: 100px;
            }
            .col1-info{
                padding: 0;
                max-width: 310px;
                margin: 0 auto 0 auto;
            }
    
            .info{
                max-width: 310px;
                margin: 0 auto 0 auto;
    
            }
            textarea{
                height: 150px;
            }
       
    }
}