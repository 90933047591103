.CallToAction{
    margin-top: 50px;
    // width: 100vw;
    width: 100vw;
    min-height: 525px ;
    background-color: rgb(231, 231, 231);
    box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5); 
    padding: 50px 0 80px 0;
   
    button{
        width: 330px;
        height: 50px;
        font-weight: bold;
        font-size: 1.4rem;
        margin-top: 25px;

    }
  
    h1{
        font-weight: bold;
        font-size: 2.5rem;
    }

 
    img{
        position: relative;
        top: 50px;
        left: 25px;
        outline: 2px solid rgb(0,168,45);
        box-shadow: -30px -30px rgb(0,168,45);
        border: solid 2px rgb(0,168,45);
        border-radius: 5px;
        min-width: 320px;
        max-width: 80%;
        height: auto;
        margin-bottom: 25px;
    }
    // @media only screen and (min-width: 767px) {
    //     min-height: 490px;
    // }
    @media only screen and (max-width: 1200px) {
        min-height: 230px;
    }

    @media only screen and (min-width: 1200px) {
        .book-meeting-text{
            position: relative;
            top: 50px;
        }
    }
    @media only screen and (max-width: 480px) {
        .book-img{
            position: relative;
            right: 10px;
        }
    }
}