.Footer{
    
    margin: 50px 0 50px 0;
    padding: 0 20vw;
    a{
        color: rgb(103, 110, 116);
    }
    a:hover{
        color: rgb(0,168,45);
    }
    h5{
        margin-top: 25px;
        color: rgb(103, 110, 116);
    }
    @media only screen and (max-width: 900px) {
        padding: 0;
    }
}