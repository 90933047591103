.PageNotFound {
  padding-top: 125px;
  min-height: 110vh;
}
.PageNotFound h1 {
  font-size: 4rem;
}
.PageNotFound p {
  font-size: 2rem;
}
.PageNotFound .container {
  margin: 25px auto;
  padding: 45px 0;
  width: 450px;
  min-height: 200px;
}/*# sourceMappingURL=PageNotFound.css.map */