.Testimonial{
    padding-top: 150px;
.thankyou{
    padding-top: 25px;
    font-weight: bold;
    font-style: italic;
    font-size: 1.2rem;
}
h1{
    max-width: 500px;
    margin: 0 auto;
}
    textarea{
        width: 315px;
        height: 225px;
        border-radius: 5px;
        padding: 10px 0 0 10px;
        border-color: lightgray;
    }
    textarea:focus{
        border-color: rgb(0,168,45);
        outline: none;
    }
    textarea::placeholder:focus {
        color: lightgray;
        
      }
    textarea::placeholder {
        color: rgb(23, 23, 23);  
        
      }

      input:focus{
        border-color: rgb(0,168,45);
      }
    button{
        margin-top: 10px;
        width: 315px;
        height: 55px;
        font-size: 1.5rem;
    }
}