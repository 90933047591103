.Specialty{
    padding: 0 12vw;
    // .pc2-img{
    //     visibility: hidden;
    // }
    .container{
        border-radius: 5px;
        padding: 50px 5vw 0 2vw;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
        background-color: rgb(249,249,248);
        min-height: 550px;
    }
    .specialty-info{
        min-width: 300px;
    }
    .specialty-h5{
        margin-bottom: 50px;
    }
    .col{
        max-height: 590px;
    }
    img{
        max-width: 350px;
        border-radius: 5px;

    }
    h1,h2{
        font-weight:bold;
    }
    h1{
        margin-bottom: 25px;
    }
    
    h2{
        font-weight: bold;
        margin-bottom: 25px;
        margin-top: 25px;

    }
   
    @media only screen and (max-width: 1199px) {
        .container{
            padding: 50px 2vw 0 2vw;
        }
        .specialty-info{
            margin: 0 auto;
            max-width: 650px;
        }

        img{
            min-width: 650px;
            margin: 0 auto;
            height: 220px;
            object-fit: cover;
            margin-bottom: 25px;
        }
       
    }
    @media only screen and (max-width: 484px) {
        
    }
    @media only screen and (max-width: 900px) {
        padding: 0;
    }
 

    @media only screen and (max-width: 768px) {
        img{ 
            min-width: 500px; 
        } 

    }
 

    @media only screen and (max-width: 534px) {
        img{ 
            min-width: 310px !important;
            margin: 0 auto;
            height: 220px;
            object-fit: cover;
            margin-bottom: 25px;

        } 

        .specialty-header{
            position: relative;
            top: 10px;
        }
        .specialty-info{
            max-width: 330px;
            min-width: 330px;
            margin: 0 auto;
        }
    }
 
 
}
