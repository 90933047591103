 
    .TechnicalSEO{
        h1{
            white-space: nowrap;
        }
    
        @media only screen and (max-width: 900px) {
            h1{
                white-space: inherit;
            }
        }
    }
 