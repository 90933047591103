.ContactHeader{
    z-index: 1;
    position: fixed;
    height: 75px;
    width: 100vw;
    background-color: rgb(0,168,45);
    padding-top: 10px;

    h1{
        position: relative;
        top: 5px;
        color: rgb(231, 231, 231);
        font-size: 2rem;
    }
    
    button{
        font-size: 1rem;
    }
    
    .logo{
        height: 50px;
        position: relative;
        bottom: 5px;
    }
    
    .phone-logo{
        width: 35px;
        
    }


    
    .number{
        font-size: 1.4rem;

    }

    .contact-header-info{
       display: inline; 
       margin: 0 45px 0 45px;

    }
    
    a{
        text-decoration: none;
    }
}