.Review {
  position: relative;
  margin-top: 25px;
}
.Review img {
  width: 175px;
  height: 175px;
  border-radius: 100%;
  position: relative;
  top: 40px;
}
.Review .container {
  background-color: rgb(231, 231, 231);
  max-width: 500px;
  padding: 25px 25px 0 25px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Review a {
  margin-bottom: 25px;
}
.Review h2 {
  margin-top: 25px;
  font-weight: bold;
}
.Review button {
  margin-top: 5px;
}
.Review .review-body {
  max-width: 500px;
}/*# sourceMappingURL=Review.css.map */