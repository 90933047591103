
.Home{
    height: 100vh;
    padding-top: 125px;
    background-color: rgba($color: #000000, $alpha: 0.15);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5); 
    overflow-x: hidden;
    h1{
        font-weight: bold;
        font-size: 3.5rem;
        margin-top: 22vh;
    }

    .white-hover:hover{
        background-color: white !important;
    }
    
    h5{
        font-weight: bold;
        font-size: 2rem;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    

   
    video{
        width: 100vw;
        min-height: 100vh;
        max-height: 100vh;

        object-fit:cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    button{
        max-width: 395px;
        height: 50px;
        font-weight: bold;
        font-size: 1.2rem;
    }
    
    @media only screen and (max-width: 410px) {
        padding-top: 75px;
        h1{
            font-size: 3rem;
        }
        h5{
            font-size: 1.7rem;
        }
    }

    
}

