.PricingCard {
  .card-container {
    border: 2px solid black;
    border-radius: 5px;
    background-color: white;
    padding: 0px 12.5px 25px 12.5px;
    max-width: 375px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 20px;
  }

  ul {
    // width: 60%;
    margin: 0 auto;
    text-align: left;
  }

  button {
    width: 325px;
    margin-top: 25px;
  }
}
