.Navbar {
    z-index: 1;
    position:fixed;
    top:0;
    height: 45px;
    width:100vw;
    margin-top: 75px;
    background-color: rgb(249,250,251);
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.1); 

    // height: 45px;
    .dropdown-name{
        border-radius: 0;
    }
    .dropdown:hover,
    .dropdown-name:hover{
        background-color:  rgba(0,168,45,0.1);
    }
.logo{
    margin: 10px auto;
    height: 60px;
    // margin: 0 auto;
    position: relative;
    bottom: 15px;
}
    
    .nav-phone{
        display: none;
    }
    .dropdown-name{
        font-size: 1.28rem;
    }
    .dropdown-name:focus{
        outline: none;
        box-shadow: none;
    }

    .dropdown-item:hover{
       background-color:  rgba(0,168,45,0.1);
        
    }
    .btn-light:active:focus, .show>.btn-light.dropdown-toggle:focus{
        box-shadow: none !important;
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
    }

    a{
        text-decoration: none;
        color: black;
    }

   

    

    // mobile navigation
    .burger {
        display: none;
        cursor: pointer;
    }

    .nav-title {
        display: none;
        font-weight: bold;
    }

    // .nav-links {
        
    //     z-index: 1;
    // }

    .burger div {
        width: 32px;
        height: 5px;
        background-color: black;
        margin: 5px;
        border-radius: 5px;
    }

    .nav-btn {
        width: 100px;
    }

    @media only screen and (max-width: 900px) {
        .nav-phone{
            position: relative;
            bottom: 125px;
            display: block;
        }
        .nav-phone h5{
            font-weight: bold;
            font-size: 1.8rem;
        }
        .dropdown-name{
            font-weight: bold;
            font-size: 1.75rem;
            width: 350px;
            background-color: rgb(249,250,251);

            
        }
        // .dropdown-menu{
        //     position: relative;
        //     // bottom: 80vh;
        //     width: 350px;
        //     background-color: rgb(249,250,251);

        // }
        .dropdown-item{
            width: 350px;
            font-size: 1.47rem;

            margin: 0 auto;
            text-align: center;
        }
        .title {
            display: none;
        }
        
        .phone-logo{
            width: 35px;
        }
        .nav-title {
            background-color: rgb(248,249,250);
            display: block;
            font-size: 2.5rem;
            z-index: 1;
            width: 100vw;
            margin: 0 auto;
            padding: 5px 0 0 10px;
            // height: 70px;
           
            
        }

        .dropdown{
            position: relative;
            bottom: 125px;
        }

     

        .nav-links {
           
            position: absolute;
            right: 0;
            width: 100vw;
            height: 100vh;
            top: 80px;
            display: flex;
            flex-direction: column;
            transition: transform 0.5s ease-in;
            padding: 25px 10% 0 10%;
            z-index: 5;
            background-color: rgb(248,249,250);
        }

        #nav-hidden {
            transition-duration: 0.4s;
            transform: translateX(100%);
        }

        #nav-active {
            transition-duration: 0.4s;
            transform: translateX(0%);
        }

        .burger {
            display: block;
            position: absolute;
            top: 25px;
            right: 50px;
        }
    }

    @media only screen and (max-width: 500px) {
        .nav-title{
            margin-top: 7px;
            font-size: 2rem;
        }
        .burger {
            position: absolute;
            right: 15px;
        }
    }

    @media only screen and (max-width: 420px) {
        .nav-title{
            margin-top: 12px;
            font-size: 1.7rem;
        }
    }
    @media only screen and (max-width: 900px) {
        margin-top: 0;
    }

}