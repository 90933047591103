.Admin {
  padding-top: 20vh;
  min-height: 100vh;
  background-color: rgb(237, 237, 237);
}
.Admin .dont-show {
  display: none;
}
.Admin .delete-btn {
  margin-top: 10px;
  width: 150px;
  height: 35px;
}
.Admin .a-container {
  width: 270px;
  margin-bottom: 25px;
}
.Admin .business-page-link {
  font-size: 1.5rem;
}
.Admin .info-btn {
  display: inline;
  padding: 5px;
  font-size: 2.5rem;
  border: none;
  position: absolute;
  top: 0px;
  right: 15px;
}
.Admin .container {
  position: relative;
  margin-top: 50px;
  border: solid 1px lightgray;
  max-width: 750px;
  padding: 25px;
  border-radius: 5px;
  background-color: whitesmoke;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.Admin .bold-header {
  font-weight: bold;
  text-align: left;
}
.Admin label {
  font-size: 1.1rem;
  font-weight: bold;
}
.Admin .form-btn {
  margin-top: 10px;
  width: 150px;
}
.Admin .form-group {
  margin: 0 10px;
  display: inline-table;
}
.Admin .form-text {
  color: black;
  position: relative;
  bottom: 10px;
}
.Admin a {
  text-decoration: none;
}
.Admin textarea {
  height: 105px;
}
.Admin .info {
  position: relative;
  left: 25px;
  text-align: left;
  max-width: 650px;
}/*# sourceMappingURL=Admin.css.map */