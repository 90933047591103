.Navbar {
  z-index: 1;
  position: fixed;
  top: 0;
  height: 45px;
  width: 100vw;
  margin-top: 75px;
  background-color: rgb(249, 250, 251);
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.1);
}
.Navbar .dropdown-name {
  border-radius: 0;
}
.Navbar .dropdown:hover,
.Navbar .dropdown-name:hover {
  background-color: rgba(0, 168, 45, 0.1);
}
.Navbar .logo {
  margin: 10px auto;
  height: 60px;
  position: relative;
  bottom: 15px;
}
.Navbar .nav-phone {
  display: none;
}
.Navbar .dropdown-name {
  font-size: 1.28rem;
}
.Navbar .dropdown-name:focus {
  outline: none;
  box-shadow: none;
}
.Navbar .dropdown-item:hover {
  background-color: rgba(0, 168, 45, 0.1);
}
.Navbar .btn-light:active:focus, .Navbar .show > .btn-light.dropdown-toggle:focus {
  box-shadow: none !important;
}
.Navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.Navbar a {
  text-decoration: none;
  color: black;
}
.Navbar .burger {
  display: none;
  cursor: pointer;
}
.Navbar .nav-title {
  display: none;
  font-weight: bold;
}
.Navbar .burger div {
  width: 32px;
  height: 5px;
  background-color: black;
  margin: 5px;
  border-radius: 5px;
}
.Navbar .nav-btn {
  width: 100px;
}
@media only screen and (max-width: 900px) {
  .Navbar .nav-phone {
    position: relative;
    bottom: 125px;
    display: block;
  }
  .Navbar .nav-phone h5 {
    font-weight: bold;
    font-size: 1.8rem;
  }
  .Navbar .dropdown-name {
    font-weight: bold;
    font-size: 1.75rem;
    width: 350px;
    background-color: rgb(249, 250, 251);
  }
  .Navbar .dropdown-item {
    width: 350px;
    font-size: 1.47rem;
    margin: 0 auto;
    text-align: center;
  }
  .Navbar .title {
    display: none;
  }
  .Navbar .phone-logo {
    width: 35px;
  }
  .Navbar .nav-title {
    background-color: rgb(248, 249, 250);
    display: block;
    font-size: 2.5rem;
    z-index: 1;
    width: 100vw;
    margin: 0 auto;
    padding: 5px 0 0 10px;
  }
  .Navbar .dropdown {
    position: relative;
    bottom: 125px;
  }
  .Navbar .nav-links {
    position: absolute;
    right: 0;
    width: 100vw;
    height: 100vh;
    top: 80px;
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in;
    padding: 25px 10% 0 10%;
    z-index: 5;
    background-color: rgb(248, 249, 250);
  }
  .Navbar #nav-hidden {
    transition-duration: 0.4s;
    transform: translateX(100%);
  }
  .Navbar #nav-active {
    transition-duration: 0.4s;
    transform: translateX(0%);
  }
  .Navbar .burger {
    display: block;
    position: absolute;
    top: 25px;
    right: 50px;
  }
}
@media only screen and (max-width: 500px) {
  .Navbar .nav-title {
    margin-top: 7px;
    font-size: 2rem;
  }
  .Navbar .burger {
    position: absolute;
    right: 15px;
  }
}
@media only screen and (max-width: 420px) {
  .Navbar .nav-title {
    margin-top: 12px;
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 900px) {
  .Navbar {
    margin-top: 0;
  }
}/*# sourceMappingURL=Navbar.css.map */