.Login {
  height: 100vh;
  padding-top: 150px;
}
.Login button {
  font-size: 1.2rem;
  width: 312px;
  height: 55px;
}
.Login input:focus {
  border-color: rgb(0, 168, 45);
}
.Login a {
  color: rgb(0, 168, 45);
}
.Login .other-form {
  margin-top: 15px;
}
.Login .logout-btn {
  margin-top: 15px;
}/*# sourceMappingURL=Login.css.map */