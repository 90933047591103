.Review{
    position: relative;
    margin-top: 25px;
    img{
        width: 175px;
        height: 175px;
        border-radius: 100%;
        position: relative;
        top: 40px;
    }
    .container{
        background-color: rgb(231, 231, 231);

        max-width: 500px;
        // min-height: 275px;
        padding: 25px 25px 0 25px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 

        border-radius: 5px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    a{
        margin-bottom: 25px;
    }
    h2{
        margin-top: 25px;
        font-weight: bold;
    }
    button{
        margin-top: 5px;
    }

   
   .review-body{
    max-width: 500px;
   }

 
}