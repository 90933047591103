
.Login{
    height: 100vh;
    padding-top: 150px;
 

    button{
        font-size: 1.2rem;
        width: 312px;
        height: 55px;
    }

    input:focus{
        border-color: rgb(0,168,45);
    }
    a{
        color: rgb(0,168,45);
    }

    .other-form{
        margin-top: 15px;
    }
   
    .logout-btn{
        margin-top: 15px;
    }
}