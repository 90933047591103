.BookAMeeting{
    p{
        rgb(231, 231, 231)-space: nowrap;
    }

    @media only screen and (max-width: 900px) {
        p{
            rgb(231, 231, 231)-space: inherit;
        }
    }
}
