.Specialty {
  padding: 0 12vw;
}
.Specialty .container {
  border-radius: 5px;
  padding: 50px 5vw 0 2vw;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  background-color: rgb(249, 249, 248);
  min-height: 550px;
}
.Specialty .specialty-info {
  min-width: 300px;
}
.Specialty .specialty-h5 {
  margin-bottom: 50px;
}
.Specialty .col {
  max-height: 590px;
}
.Specialty img {
  max-width: 350px;
  border-radius: 5px;
}
.Specialty h1, .Specialty h2 {
  font-weight: bold;
}
.Specialty h1 {
  margin-bottom: 25px;
}
.Specialty h2 {
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 25px;
}
@media only screen and (max-width: 1199px) {
  .Specialty .container {
    padding: 50px 2vw 0 2vw;
  }
  .Specialty .specialty-info {
    margin: 0 auto;
    max-width: 650px;
  }
  .Specialty img {
    min-width: 650px;
    margin: 0 auto;
    height: 220px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 900px) {
  .Specialty {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .Specialty img {
    min-width: 500px;
  }
}
@media only screen and (max-width: 534px) {
  .Specialty img {
    min-width: 310px !important;
    margin: 0 auto;
    height: 220px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 25px;
  }
  .Specialty .specialty-header {
    position: relative;
    top: 10px;
  }
  .Specialty .specialty-info {
    max-width: 330px;
    min-width: 330px;
    margin: 0 auto;
  }
}/*# sourceMappingURL=Specialty.css.map */