.About {
  padding-top: 75px;
  padding: 75px 0;
}
.About h1, .About h2, .About h2 {
  font-weight: bold;
}
.About p {
  margin: 0 auto;
  max-width: 800px;
  letter-spacing: 1.5px;
  font-size: 1.2rem;
}
.About h2 {
  margin-top: 50px;
}
.About h1 {
  font-size: 4rem;
}
.About .about-us {
  margin: 0 auto;
  padding: 75px 15px 25px 15px;
  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5);
  background-color: rgb(249, 250, 251);
  min-height: 280px;
}
.About .testimonial-title {
  margin: 25px 0;
  font-size: 3.5rem;
}
.About .our-why {
  margin: 25px auto 50px auto;
  padding: 0 10px;
}
.About .started-container {
  padding: 0 10px;
}
.About .started {
  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5);
  max-width: 900px;
  padding: 25px 15px 45px 15px;
  margin: 25px auto 0 auto;
  background-color: rgb(249, 250, 251);
  border: 1 px solid black;
  border-radius: 5px;
}
.About .testimonials-container {
  padding: 0 10px;
}
.About .testimonials-container h1 {
  max-width: 900px;
  margin: 25px auto 25px auto;
}
.About .testimonials {
  max-width: 900px;
  padding: 25px 25px;
  margin: 0 auto;
  min-height: 150px;
  border-radius: 5px;
  background-color: rgb(249, 250, 251);
  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.About .customer-name {
  text-align: right;
  position: relative;
  right: 50px;
}
.About .values {
  margin: 25px auto 0 auto;
  max-width: 900px;
}
.About .value-container {
  margin-top: 25px;
  position: relative;
  min-width: 150px;
  height: 150px;
  border-radius: 5px;
  background-color: rgb(249, 250, 251);
  text-align: center;
  border: solid 1.75px rgb(0, 168, 45);
  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.About .value-container:hover {
  position: relative;
  bottom: 5px;
}
.About a {
  color: rgb(0, 168, 45);
}
.About .value {
  margin: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}/*# sourceMappingURL=About.css.map */