.Services {
  margin-bottom: 100px;
  padding: 0 10vw;
  font-weight: bold;
  background-color: rgb(249, 249, 248);
  z-index: -2;
  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5);
  position: relative;
}
.Services img {
  z-index: -1;
  width: 100vw;
  height: 530px;
  position: absolute;
  bottom: 0px;
  left: 25px;
}
.Services .icon {
  width: 40px;
  height: 40px;
  position: relative;
  margin-right: 35px;
}
.Services li {
  font-size: 1.2rem;
  font-weight: 300;
}
.Services h1 {
  z-index: 2;
  font-weight: bold;
}
.Services .business-helper-heading {
  margin-bottom: 25px;
}
.Services .container {
  z-index: 1;
  padding: 75px 10px;
  min-height: 525px;
}
.Services .first-list {
  position: relative;
  left: 50px;
  white-space: nowrap !important;
}
.Services .second-list {
  position: relative;
  white-space: nowrap !important;
}
.Services .services-row {
  position: relative;
  right: 80px;
  margin-bottom: 10px;
}
.Services .service-info-container {
  position: relative;
}
.Services li {
  list-style: none;
  padding-left: 10px;
  text-align: left;
  margin: 20px 0;
}
.Services .services-col {
  max-width: 550px;
  margin: 0 auto;
}
@media only screen and (max-width: 1200px) {
  .Services .service-p {
    margin: 0 auto;
    max-width: 600px;
  }
}
@media only screen and (max-width: 900px) {
  .Services {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .Services .container {
    padding: 7vh 10px 0px 10px;
  }
  .Services .service-heading {
    margin-top: 25px;
  }
}
@media only screen and (max-width: 576px) {
  .Services .first-list {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Services .service-p {
    margin: 0 auto;
    max-width: 300px;
    min-width: 300px;
  }
  .Services .second-p {
    position: relative;
    top: 10px;
  }
  .Services .second-list {
    display: none;
  }
}/*# sourceMappingURL=Services.css.map */