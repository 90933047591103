.Reviews{
    .prev-btn{
        position: relative;
        right: 25px;
        height: 75px;
    }

    .review-img-btn{
        padding-bottom: 5px;
        margin-top: 25px;
        display: inline;
        width: 100px;
        cursor: pointer;
        height: 45px;
        transition: all 0.5s;     
        transition: 0.5s;
        font-size: 2.5rem;
        margin-bottom: 15px;
        text-align: center;
    }
 
    p{
        font-size: 1rem;
        position: relative;
        top: 2px;
    }
      
    .next-btn:after {
        content: '»';
        position: absolute;
        opacity: 0;  
        bottom: -9px;
        right: -10px;
        transition: 0.5s;
    }
      
    .next-btn:hover{
        padding-right: 24px;
        padding-left:8px;
    }
      
    .next-btn:hover:after {
        opacity: 1;
        right: 10px;
    }

    .prev-btn:after {
        content: '«';
        position: absolute;
        opacity: 0;  
        bottom: -9px;
        left: -10px;
        transition: 0.5s;
    }
      
    .prev-btn:hover{
        padding-left: 24px;
        padding-right:8px;
    }
      
    .prev-btn:hover:after {
        opacity: 1;
        left: 10px;
    }




    .next-btn{
        position: relative;
        left: 25px;
    }


    
}