.PageNotFound{
    padding-top: 125px  ;
    min-height: 110vh;

    h1{
        font-size: 4rem;
    }

    p{
        font-size: 2rem;
    }
    .container{
        margin: 25px auto ;
        padding: 45px 0;
        width: 450px;
        // border-radius: 5px;
        // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
        // background-color: rgb(231, 231, 231);
        min-height: 200px;
    }
}