.PricingTier {
  padding-top: 175px;
  min-height: 100vh;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
}
