.form {
  min-height: 125vh;
  padding: 75px 10px 0 10px;
}
.form h1 {
  font-size: 1.97rem;
}
.form .container {
  margin-top: 100px;
  padding: 25px 15px;
  max-width: 800px;
  border-radius: 5px;
  min-height: 525px;
  background-color: whitesmoke;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.form p {
  font-size: 0.85rem;
}
.form textarea {
  padding: 15px 15px 0 10px;
  height: 205px;
  width: 310px;
  border-radius: 5px;
  margin-top: 15px;
  border-color: lightgray;
}
.form textarea:focus {
  border-color: rgb(0, 168, 45);
  outline: none;
}
.form textarea::-moz-placeholder {
  color: black;
}
.form textarea:-ms-input-placeholder {
  color: black;
}
.form textarea::placeholder {
  color: black;
}
.form input {
  max-width: 310px;
}
.form input:focus {
  border-color: rgb(0, 168, 45);
}
.form button {
  font-weight: bold;
  font-size: 1.5rem;
  height: 60px;
  width: 310px;
}
@media only screen and (max-width: 693px) {
  .form {
    padding: 125px 10px 0 10px;
  }
  .form .container {
    margin-top: 15px;
    margin-bottom: 100px;
  }
  .form h1 {
    font-size: 3rem;
  }
  .form .info {
    max-width: 350px;
    margin: 0 auto 0 auto;
  }
  .form textarea {
    height: 150px;
  }
}/*# sourceMappingURL=BusinessForm.css.map */