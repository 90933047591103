.Testimonial {
  padding-top: 150px;
}
.Testimonial .thankyou {
  padding-top: 25px;
  font-weight: bold;
  font-style: italic;
  font-size: 1.2rem;
}
.Testimonial h1 {
  max-width: 500px;
  margin: 0 auto;
}
.Testimonial textarea {
  width: 315px;
  height: 225px;
  border-radius: 5px;
  padding: 10px 0 0 10px;
  border-color: lightgray;
}
.Testimonial textarea:focus {
  border-color: rgb(0, 168, 45);
  outline: none;
}
.Testimonial textarea::-moz-placeholder:focus {
  color: lightgray;
}
.Testimonial textarea:-ms-input-placeholder:focus {
  color: lightgray;
}
.Testimonial textarea::placeholder:focus {
  color: lightgray;
}
.Testimonial textarea::-moz-placeholder {
  color: rgb(23, 23, 23);
}
.Testimonial textarea:-ms-input-placeholder {
  color: rgb(23, 23, 23);
}
.Testimonial textarea::placeholder {
  color: rgb(23, 23, 23);
}
.Testimonial input:focus {
  border-color: rgb(0, 168, 45);
}
.Testimonial button {
  margin-top: 10px;
  width: 315px;
  height: 55px;
  font-size: 1.5rem;
}/*# sourceMappingURL=Testimonial.css.map */