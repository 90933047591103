.Admin{
    padding-top: 20vh;
    min-height: 100vh;
    background-color: rgb(237, 237, 237);

    .dont-show{
        display: none;
    }

    .delete-btn{
        margin-top: 10px;
        width: 150px;
        height: 35px;
       
    }

    .a-container{
        width: 270px;
        margin-bottom: 25px;
    }
    .business-page-link{
        font-size: 1.5rem;
    }
    .info-btn{
       
        display: inline;
        padding: 5px;
        font-size:2.5rem;
        border: none;
        position: absolute;
        top: 0px;
        right: 15px;
    }
    .container{
        position: relative;
        margin-top: 50px;
        border: solid 1px lightgray ;
        max-width: 750px;
        padding: 25px;
        border-radius: 5px;
        background-color: whitesmoke;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
    }

    .bold-header{
        font-weight: bold;
        text-align: left;
    }
    label{
        font-size: 1.1rem;
        font-weight: bold;
    }
    .form-btn{
        margin-top: 10px;
        width: 150px;
    }
    .form-group{
       
        margin: 0 10px;
        display: inline-table;
    }
    .form-text{
        color: black;
        position: relative;
        bottom: 10px;
    }
    a{
        text-decoration: none;
    }
    textarea{
        height: 105px;
    }
    .info{
        position: relative;
        left: 25px;
        text-align: left;
        max-width: 650px;
    }

}