.FormTemplate {
  min-height: 125vh;
  padding: 100px 10px 0 10px;
}
.FormTemplate h1 {
  font-size: 3rem;
  font-weight: bold;
}
.FormTemplate h5 {
  text-align: left;
}
.FormTemplate a {
  color: black;
  text-decoration: underline;
}
.FormTemplate .container {
  position: relative;
  margin-top: 100px;
  padding: 25px 15px;
  max-width: 800px;
  border-radius: 5px;
  height: 450px;
  background-color: whitesmoke;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.FormTemplate .col1-info {
  padding-left: 45px;
}
.FormTemplate p {
  font-size: 0.85rem;
}
.FormTemplate textarea {
  padding: 15px 15px 0 10px;
  height: 150px;
  width: 310px;
  border-radius: 5px;
  border-color: lightgray;
}
.FormTemplate textarea:focus {
  border-color: rgb(0, 168, 45);
  outline: none;
}
.FormTemplate textarea::-moz-placeholder {
  color: black;
}
.FormTemplate textarea:-ms-input-placeholder {
  color: black;
}
.FormTemplate textarea::placeholder {
  color: black;
}
.FormTemplate input {
  max-width: 310px;
}
.FormTemplate input:focus {
  border-color: rgb(0, 168, 45);
}
.FormTemplate button {
  font-weight: bold;
  font-size: 1.5rem;
  height: 60px;
  width: 310px;
  margin-top: 10px;
}
@media only screen and (max-width: 732px) {
  .FormTemplate .container {
    max-width: 420px;
    height: 850px;
    margin-top: 15px;
    margin-bottom: 100px;
  }
  .FormTemplate .col1-info {
    padding: 0;
    max-width: 310px;
    margin: 0 auto 0 auto;
  }
  .FormTemplate .info {
    max-width: 310px;
    margin: 0 auto 0 auto;
  }
  .FormTemplate textarea {
    height: 150px;
  }
}/*# sourceMappingURL=FormTemplate.css.map */