.Footer {
  margin: 50px 0 50px 0;
  padding: 0 20vw;
}
.Footer a {
  color: rgb(103, 110, 116);
}
.Footer a:hover {
  color: rgb(0, 168, 45);
}
.Footer h5 {
  margin-top: 25px;
  color: rgb(103, 110, 116);
}
@media only screen and (max-width: 900px) {
  .Footer {
    padding: 0;
  }
}/*# sourceMappingURL=Footer.css.map */