.Services{
    margin-bottom: 100px;
    padding: 0 10vw;
    font-weight: bold;
    background-color: rgb(249,249,248);
    z-index: -2;
    box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5); 
    position: relative;
    
    img{
        z-index: -1;
        width: 100vw;
        height: 530px;
        position: absolute;
        bottom: 0px;
        left: 25px;        
    }
    
    .icon{
        width:40px;
        height: 40px;
        position: relative;
        margin-right: 35px;
    }

li{
    font-size: 1.2rem;
    font-weight: 300;
}
    h1{
        z-index: 2;
        font-weight: bold;
    }
    .business-helper-heading{
    
        margin-bottom: 25px;
    }
    // #23518C
    .container{
        z-index: 1;
        padding: 75px 10px;
        min-height: 525px;
    }
    .first-list{
        position: relative;
        left: 50px;
        white-space: nowrap !important;

    }
    .second-list{
      position: relative; 
        white-space: nowrap !important;
        
    }

  
    .services-row{
        position: relative;
        right: 80px;
        margin-bottom: 10px; 

    }
    .service-heading{
        // margin-top: 85px;
        
    }

    
    .service-info-container{
    position: relative;
    // margin-top: 100px;
    }

  
    li{
        list-style: none;
        padding-left: 10px;
        text-align: left;
        margin: 20px 0;
    }
    
    .services-col{
        max-width: 550px;
        margin: 0 auto;
    }

    @media only screen and (max-width: 1200px) {
    .service-p{
        margin: 0 auto;
        max-width: 600px;
        
    }}
    @media only screen and (max-width: 900px) {
        padding: 0;
    }
 

    @media only screen and (max-width: 768px) {
        .container{
            padding: 7vh 10px 0px 10px;
         }
   
         .service-heading{
            margin-top: 25px;
         }
    }


    @media only screen and (max-width: 576px) {
        .first-list{
            width: 100vw;
        //    margin: 0 auto;
           display: flex;
           justify-content: center;
           align-items: center; 
        //    position: relative;
        //    right: 50px;
        }

        .service-p{
            margin: 0 auto;
            max-width: 300px;
            min-width: 300px;
        }

        .second-p{
            position: relative;
            top: 10px;
        }
    .second-list{
        display: none;
    }
    }
    }