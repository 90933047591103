.Home {
  height: 100vh;
  padding-top: 125px;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
}
.Home h1 {
  font-weight: bold;
  font-size: 3.5rem;
  margin-top: 22vh;
}
.Home .white-hover:hover {
  background-color: white !important;
}
.Home h5 {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 25px;
}
.Home video {
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.Home button {
  max-width: 395px;
  height: 50px;
  font-weight: bold;
  font-size: 1.2rem;
}
@media only screen and (max-width: 410px) {
  .Home {
    padding-top: 75px;
  }
  .Home h1 {
    font-size: 3rem;
  }
  .Home h5 {
    font-size: 1.7rem;
  }
}/*# sourceMappingURL=Home.css.map */