.Quote h1 {
  rgb(231, 231, 231)-space: nowrap;
}

.Quote textarea {
  height: 185px;
}

@media only screen and (max-width: 900px) {
  .Quote h1 {
    rgb(231, 231, 231)-space: inherit;
  }
}

/*# sourceMappingURL=Quote.css.map */