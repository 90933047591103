.Quote{
    h1{
        rgb(231, 231, 231)-space: nowrap;
    }

    textarea{
        height: 185px;
    }

    @media only screen and (max-width: 900px) {
        h1{
            rgb(231, 231, 231)-space: inherit;
        }
    }
}
