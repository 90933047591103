.Testimonials {
  padding-top: 150px;
}
.Testimonials .container {
  max-width: 350px;
  margin-top: 50px;
  border: solid 1px lightgray;
  padding: 25px;
  border-radius: 5px;
  background-color: whitesmoke;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.Testimonials .delete-btn {
  margin: 10px auto 0 auto;
}
.Testimonials span input {
  display: inline;
}
.Testimonials span p {
  display: inline;
}
.Testimonials span button {
  margin-left: 120px;
  display: inline;
}/*# sourceMappingURL=Testimonials.css.map */