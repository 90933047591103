.About{
    padding-top: 75px;
    padding: 75px  0 ;
    h1,h2,h2{
        font-weight: bold;
    }
    p{
        margin: 0 auto;
        max-width: 800px;
        letter-spacing: 1.5px;
        font-size: 1.2rem;
    }
    h2{
        margin-top: 50px;
    }

    h1{
        font-size: 4rem;
    }
    .about-us{
        margin:0 auto;
        padding: 75px 15px 25px 15px;
        box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5); 
        background-color: rgb(249,250,251);
        min-height: 280px;


    }

    .testimonial-title{
        margin: 25px 0;
        font-size: 3.5rem;
    }
    
    .our-why{
        margin: 25px auto 50px auto;
        padding: 0 10px;
    }
    .started-container{
        padding: 0 10px;
    }
    .started{
        box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5); 
        max-width: 900px;
        padding: 25px 15px 45px 15px;
        margin: 25px auto 0 auto;
        background-color: rgb(249,250,251);
        border: 1 px solid black;
        border-radius: 5px;
    }

    .testimonials-container{
        
        padding: 0 10px;
    }
    .testimonials-container{
        h1{

            max-width: 900px;
            margin: 25px auto 25px auto;
        }
    }
    .testimonials{
        max-width: 900px;
        padding: 25px 25px;
        margin: 0 auto;
        min-height: 150px;
        border-radius: 5px;

        background-color: rgb(249,250,251) ;
        box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5); 

    }
    .customer-name{
        text-align: right;
        position: relative;
        right: 50px;

    }

    .services{

    }

    .values{
        
        margin: 25px auto 0 auto;
        max-width: 900px;

    }

    .value-container{
        margin-top: 25px;
        position: relative;
        min-width: 150px;
        height: 150px;
        border-radius: 5px;
        background-color: rgb(249,250,251);
        text-align: center;
        border: solid 1.75px rgb(0, 168, 45);
        box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.5); 

    }

    .value-container:hover{
        position: relative;
        bottom: 5px;
    }
    a{
        color: rgb(0, 168, 45);
    }
    
    .value{
        margin: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}