@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
$green1: rgb(0, 168, 45);

html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

* {
  margin: 0;
  padding: 0;
  // z-index: hidden;
.light-green-background{
  background-color:  rgba(0,168,45,0.1);
}

  a:active {
    color: black;
  }
  // rgb(231, 231, 231)
  body {    
    background-color: #d0d0d0;
    letter-spacing: 1.25px;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;

    a{
      text-decoration: none;
    }

    
  }

 
  label {
    display: block;
  }

  button {
    display: block;
    margin: 0 auto;
  }

  .form-label {
    margin: 15px auto;
    max-width: 312px;
  }

  .form-input:focus {
    border-color: black;
    box-shadow: none;
    outline: 0 none;
  }

  .form-btn {
    width: 312px;
    height: 35px;
    font-size: 1.25rem;
    border: 2px solid black;
    border-radius: 4px;
  }


  // diffrent varients of buttons
  .light-btn {
    color: rgb(231, 231, 231);
    background-color: rgba(255, 255, 255, 0);
    border: solid 2px rgb(231, 231, 231);
    border-radius: 5px;
    padding: 7px;
  }

  .light-btn:hover {
    color: black;
    box-shadow:9px 9px 4px -6px hsla(0,0%,0%,.3);

    border: solid 2px black;
  }

  
  .red-outline-btn {
    color: red;
    background-color: rgba(255, 255, 255, 0);
    border: solid 2px red;
    border-radius: 5px;
    padding: 7px;
  }

  .red-outline-btn:hover {
    box-shadow:9px 9px 4px -6px hsla(0,0%,0%,.3);
    color: black;
    background-color: red;
    border: solid 2px red;
  }

  .green-outline-btn {
    color: rgb(0, 168, 45);
    background-color: rgba(255, 255, 255, 0);
    border: solid 2px rgb(0, 168, 45);
    border-radius: 5px;
    padding: 7px;
  }

  .green-outline-btn:hover {
    box-shadow:9px 9px 4px -6px hsla(0,0%,0%,.3);

    color: black;
    background-color: rgb(0, 168, 45);
    border: solid 2px rgb(0, 168, 45);
  }

  .green-filled-btn {
    
    border-radius: 5px;
    padding: 7px;

    color: black;
    background-color: rgb(0, 168, 45);
    border: solid 2px rgb(0, 168, 45);
  }

  .green-filled-btn:hover {
    box-shadow:9px 9px 4px -6px hsla(0,0%,0%,.3);
    color: rgb(0, 168, 45);
    background-color: rgba(255, 255, 255, 0);
    border: solid 2px rgb(0, 168, 45);
    
  }

  @media only screen and (max-width: 900px) {
    .ContactHeader {
      display: none;
    }
  }

  .blue-header {
    color: #011C40;
    font-weight: bold;
  }

  .green-header {
    color: rgb(0, 168, 45);
    font-weight: bold;
  }

  .black-header {
    color: black;
    font-weight: bold;
  }

  .green {
    color: rgb(0, 168, 45);
    font-weight: bold;
  }

  .green:hover {
    color: rgb(0, 168, 45);
    font-weight: bold;
  }



  .green-a{
    color: rgb(0, 168, 45);
    font-weight: bold;
  }

.a-container{
  position: relative;
  margin: 0 auto;
  
  .green-a:hover {
    color: rgb(0, 168, 45);
  }
  .green-a::before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 3px;
    top: 40px;
    background-color: rgb(0, 168, 45);
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  .green-a:hover::before {
    transform: scaleX(1);
  }
}
 

}