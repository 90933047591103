.SignUp {
  height: 100vh;
  padding-top: 150px;
}
.SignUp button {
  font-size: 1.2rem;
  width: 312px;
  height: 55px;
}
.SignUp input:focus {
  border-color: rgb(0, 168, 45);
}
.SignUp a {
  color: rgb(0, 168, 45);
}
.SignUp .other-form {
  margin-top: 15px;
}/*# sourceMappingURL=SignUp.css.map */