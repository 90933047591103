.PricingTier {
  padding-top: 175px;
  min-height: 100vh;
}
.PricingTier .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}/*# sourceMappingURL=PricingTier.css.map */