.ContactHeader {
  z-index: 1;
  position: fixed;
  height: 75px;
  width: 100vw;
  background-color: rgb(0, 168, 45);
  padding-top: 10px;
}
.ContactHeader h1 {
  position: relative;
  top: 5px;
  color: rgb(231, 231, 231);
  font-size: 2rem;
}
.ContactHeader button {
  font-size: 1rem;
}
.ContactHeader .logo {
  height: 50px;
  position: relative;
  bottom: 5px;
}
.ContactHeader .phone-logo {
  width: 35px;
}
.ContactHeader .number {
  font-size: 1.4rem;
}
.ContactHeader .contact-header-info {
  display: inline;
  margin: 0 45px 0 45px;
}
.ContactHeader a {
  text-decoration: none;
}/*# sourceMappingURL=ContactHeader.css.map */